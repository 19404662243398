@tailwind base;
@tailwind components;
@tailwind utilities;

/* Swiper pagination custom  */
.kv-pagination .swiper-pagination-bullet {
  opacity: 1 !important;
  background: none !important;
  font-weight: bold !important;
  color: #ffffff !important;
  margin: 0 8px !important;
  width: auto !important;
  height: auto !important;
  font-size: 12px !important;
  transition: all 0.2s ease-in-out;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.3);
}

.kv-pagination .swiper-pagination-bullet-active {
  color: #6e95ff !important;
}

.dot-pagination .swiper-pagination-bullet {
  background-color: #cad1d9 !important;
  margin: 0 5px !important;
  opacity: 1 !important;
  width: 6px !important;
  height: 6px !important;
}

.culture-pagination .swiper-pagination-bullet,
.card-pagination .swiper-pagination-bullet {
  background-color: #242a32 !important;
}

@media (max-width: 769px) {
  .dot-pagination .swiper-pagination-bullet {
    margin: 0 4px !important;
    width: 4px !important;
    height: 4px !important;
  }

  .culture-pagination .swiper-pagination-bullet {
    width: 6px !important;
    height: 6px !important;
    margin: 0 5px !important;
  }
}

.dot-pagination .swiper-pagination-bullet-active {
  background-color: #6e95ff !important;
}

.top-slide .swiper-wrapper {
  transition-timing-function: linear !important;
}

.slider-cards .swiper {
  overflow: visible;
}

.culture-swiper .swiper-wrapper {
  transition:
    opacity 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.16s,
    transform 1s cubic-bezier(0.215, 0.61, 0.355, 1) 0.16s !important;
}

/* Top banner  pagination */
.banner .circle {
  stroke-dashoffset: calc(120 * (1 - var(--progress)));
}

/* Gradient background  */
#our-gradient,
#card-gradient {
  width: 100%;
  height: 100%;
  --gradient-color-1: #f5f5fb;
  --gradient-color-2: #cee4f9;
  --gradient-color-3: #edeffa;
  --gradient-color-4: #abd0ef;
}
#our-gradient.our-ignite {
  --gradient-color-1: #f5f5fb;
  --gradient-color-2: #cee4f9;
  --gradient-color-3: #edeffa;
  --gradient-color-4: #abd0ef;
}
#our-gradient.our-reason {
  --gradient-color-1: #d8e6f8;
  --gradient-color-2: #b9d5e9;
  --gradient-color-3: #f5f5f5;
  --gradient-color-4: #e1e5ea;
}
#joinus-gradient {
  --gradient-color-1: #b0c9ee;
  --gradient-color-2: #ded0d5;
  --gradient-color-3: #e3dfdf;
  --gradient-color-4: #e3e3e3;
}
.cardSwiper .swiper-slide img {
  transition: all 0.3s ease-in-out !important;
}

/* detailContentStyle */

.detailContentStyle *:not(li, dt) {
  margin-top: 24px;
}

.detailContentStyle h3 {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.7;
  letter-spacing: 0.02em;
  margin-top: 40px;
}

.detailContentStyle h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.7;
  letter-spacing: 0.02em;
  margin-top: 32px;
}

.detailContentStyle h5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.7;
  letter-spacing: 0.02em;
  margin-top: 28px;
}

.detailContentStyle h6 {
  color: #aab4c0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 200%;
  letter-spacing: 0.02em;
}

.blue {
  color: #6e95ff;
}

.detailContentStyle p {
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0.02em;
}

.detailContentStyle small {
  font-size: 12px;
  line-height: 2;
  color: #aab4c0;
  display: block;
}

.detailContentStyle ul {
  list-style: disc;
}

.detailContentStyle ol {
  list-style: decimal;
}

.detailContentStyle ol li,
.detailContentStyle ul li {
  font-size: 14px;
  font-weight: 700;
  line-height: 2;
  margin-left: 20px;
  letter-spacing: 2px;
}

.detailContentStyle blockquote {
  padding-left: 32px;
  font-size: 14px;
  line-height: 2;
  border-left: 5px solid #cad1d9;
  color: #aab4c0;
}

.detailContentStyle hr {
  border-top: 1px solid #cad1d9;
  margin: 56px 0;
}

.detailContentStyle hr.dot {
  border-style: dashed;
  margin: 16px 0 40px;
}

.detailContentStyle img {
  border-radius: 3px;
  margin-top: 56px;
}

.detailContentStyle dl {
  display: flex;
  flex-wrap: wrap;
}

.detailContentStyle dl dt {
  width: calc((100% - 32px) / 3);
  margin-right: 16px;
  margin-bottom: 24px;
}

.detailContentStyle dl dt:nth-child(3n) {
  margin-right: 0;
}
/* .detailContentStyle dl dt:nth-child(4) {
  margin-top: 24px;
} */

.detailContentStyle dl dt img {
  margin-top: 0;
}

.detailContentStyle dl dt p {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7;
  letter-spacing: 0.02em;
  margin-top: 12px;
}

.detailContentStyle dl dt span {
  display: block;
  font-size: 11px;
  color: #797979;
  margin: 0;
}

.detailContentStyle section {
  margin-top: 56px;
}

.detailContentStyle section p {
  margin-top: 8px;
}

.detailContentStyle table {
  width: 100% !important;
  display: block;
  padding: 32px 40px;
  background-color: #f2f7fd;
}

.detailContentStyle table p {
  color: #5c6f83;
  margin-top: 0;
  font-size: 14px;
}

.detailContentStyle table tbody,
.detailContentStyle table tr,
.detailContentStyle table td {
  display: block;
  margin-top: 0;
  padding: 0;
}

.detailContentStyle table tr + tr {
  margin-top: 24px;
}

.detailContentStyle table td p + p {
  margin-top: 8px;
}

@media (max-width: 769px) {
  .detailContentStyle h3 {
    font-size: 20px;
    margin-top: 32px;
  }

  .detailContentStyle h4 {
    font-size: 18px;
    margin-top: 28px;
  }

  .detailContentStyle h5 {
    font-size: 16px;
    margin-top: 24px;
  }

  .detailContentStyle p {
    font-size: 14px;
  }

  .detailContentStyle hr {
    margin: 40px 0;
  }

  .detailContentStyle ol li,
  .detailContentStyle ul li {
    font-size: 12px;
    margin-left: 20px;
    letter-spacing: 2px;
  }

  .detailContentStyle img {
    border-radius: 3.55px;
    margin-top: 8px;
  }

  .detailContentStyle blockquote {
    padding-left: 20px;
    font-size: 12px;
  }

  .detailContentStyle dl {
    display: block;
  }

  .detailContentStyle dl dt {
    width: 100%;
    display: flex;
    align-items: center;
    margin-right: 0;
  }

  .detailContentStyle dl dt:last-child {
    margin-bottom: 0;
  }

  .detailContentStyle dl dt img {
    width: 49%;
  }

  .detailContentStyle dl dt:not(:last-child) {
    margin-bottom: 16px;
  }

  .detailContentStyle dl dt p {
    margin-top: 0;
    margin-left: 16px;
  }

  .detailContentStyle section {
    margin-top: 40px;
  }

  .detailContentStyle table {
    padding: 32px 24px;
  }

  .detailContentStyle table p {
    font-size: 12px;
  }

  .detailContentStyle table tr + tr {
    margin-top: 16px;
  }

  .detailContentStyle table td p + p {
    margin-top: 4px;
  }
}

/* introCompanyStyle */

.introCompanyStyle > div > section {
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 32px;
}
.introCompanyStyle > div > section > div {
  border-radius: 8px;
  background-color: #f2f7fd;
  height: 320px;
  position: relative;
  overflow: hidden;
}
.introCompanyStyle > div > section > div h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7;
  letter-spacing: 0.02em;
  padding: 24px 28px 12px;
  color: #5c6f83;
  position: relative;
  z-index: 1;
}
.introCompanyStyle > div > section > div h6 > span {
  font-size: 9px;
  position: absolute;
  margin-top: 1px;
}
.introCompanyStyle > div > section > div h3 {
  text-align: center;
  font-weight: 600;
  position: relative;
  z-index: 1;
}
.introCompanyStyle > div > section > div h3 > span:nth-child(1) {
  font-size: 72px;
  font-weight: 600;
  line-height: 1.7;
  letter-spacing: -0.03em;
  font-family: var(--font-avenirNextDemi);
  color: #6e95ff;
}
.introCompanyStyle > div > section > div h3 > span:nth-child(2) {
  font-size: 24px;
  font-weight: 600;
  font-family: var(--notosanjp);
  color: #5c6f83;
  padding-left: 8px;
}
.introCompanyStyle > div > section > div h3 + img {
  margin: 0 auto;
}
.introCompanyStyle > div > section > div > small {
  font-size: 10px;
  line-height: 1.7;
  letter-spacing: 0.02em;
  padding: 12px 16px;
  display: block;
  color: #5c6f83;
  position: absolute;
  bottom: 0;
}
.introCompanyStyle > div > section > div:nth-child(1) h3 {
  margin-top: 12px;
}
.introCompanyStyle > div > section > div:nth-child(2) h3 > span:nth-child(1),
.introCompanyStyle > div > section > div:nth-child(6) h3 > span:nth-child(1),
.introCompanyStyle > div > section > div:nth-child(8) h3 > span:nth-child(2) {
  font-size: 88px;
  color: #6e95ff;
  font-family: var(--font-avenirNextDemi);
}
.introCompanyStyle > div > section > div:nth-child(6) h3 {
  margin-bottom: -15px;
}
.introCompanyStyle > div > section > div:nth-child(7) h3,
.introCompanyStyle > div > section > div:nth-child(8) h3 {
  margin-top: 10px;
  margin-bottom: -10px;
}
.introCompanyStyle > div > section > div:nth-child(7) h3 > span:nth-child(1) {
  font-size: 72px;
}
.introCompanyStyle > div > section > div:nth-child(8) h3 > span:nth-child(2) {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.7;
  letter-spacing: 0.02em;
  color: #5c6f83;
  font-family: var(--notosanjp);
}
.introCompanyStyle > div > section > div:nth-child(8) h3 > span:nth-child(3) {
  padding-left: 8px;
}
.introCompanyStyle > div > section > div:nth-child(1) img {
  margin: 0 auto;
}
.introCompanyStyle > div > section > div:nth-child(2) img {
  margin: -12px auto 0;
}
.introCompanyStyle > div > section > div:nth-child(3) > div {
  display: flex;
  justify-content: center;
  text-align: center;
}
.introCompanyStyle > div > section > div:nth-child(3) > div div {
  margin: 0 14px;
}
.introCompanyStyle > div > section > div:nth-child(3) > div div h3 {
  margin-bottom: -12px;
}
.introCompanyStyle
  > div
  > section
  > div:nth-child(3)
  > div
  div
  h3
  > span:nth-child(1) {
  font-size: 60px;
}
.introCompanyStyle > div > section > div:nth-child(3) > div div > p {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.7;
  color: #5c6f83;
  text-align: center;
  margin-top: -5px;
}
.introCompanyStyle > div > section > div:nth-child(3) > div div > img {
  margin: 0 auto;
  display: block;
}

.introCompanyStyle
  > div
  > section
  > div:nth-child(3)
  > div
  div
  h3
  > span:nth-child(2) {
  font-size: 40px;
  padding: 0;
  color: #6e95ff;
}
.introCompanyStyle
  > div
  > section
  > div:nth-child(3)
  > div
  div
  h3
  > span:nth-child(3) {
  font-size: 20px;
  padding-left: 4px;
  color: #5c6f83;
  line-height: 1.7;
  letter-spacing: 0.02em;
}
.introCompanyStyle > div > section > div:nth-child(3) > small {
  margin-top: 17px;
}
.introCompanyStyle > div > section > div:nth-child(1),
.introCompanyStyle > div > section > div:nth-child(6) {
  grid-column-start: 1;
  grid-column-end: 3;
}
.introCompanyStyle > div > section > div:nth-child(2),
.introCompanyStyle > div > section > div:nth-child(7) {
  grid-column-start: 3;
  grid-column-end: 5;
}
.introCompanyStyle > div > section > div:nth-child(3),
.introCompanyStyle > div > section > div:nth-child(8) {
  grid-column-start: 5;
  grid-column-end: 7;
}
.introCompanyStyle > div > section > div:nth-child(4) {
  grid-column-start: 1;
  grid-column-end: 4;
}
.introCompanyStyle > div > section > div:nth-child(5) {
  grid-column-start: 4;
  grid-column-end: 7;
}
.introCompanyStyle > div > section > div:nth-child(5) > div {
  display: flex;
  align-items: center;
  max-width: 450px;
  width: 100%;
  margin: 10px auto 0;
  padding: 0 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.introCompanyStyle
  > div
  > section
  > div:nth-child(5)
  > div
  > h3
  > span:nth-child(2) {
  color: #6e95ff;
  font-size: 64px;
  padding: 0;
}
.introCompanyStyle
  > div
  > section
  > div:nth-child(5)
  > div
  > h3
  > span:nth-child(3) {
  color: #5c6f83;
  font-size: 28px;
}
.introCompanyStyle > div > section > div:nth-child(5) ul {
  margin-right: 40px;
}
.introCompanyStyle > div > section > div:nth-child(5) ul > li {
  display: flex;
  align-items: center;
}
.introCompanyStyle > div > section > div:nth-child(5) ul > li:not(:last-child) {
  margin-bottom: 14px;
}
.introCompanyStyle > div > section > div:nth-child(5) ul > li > span {
  padding-right: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.7;
  letter-spacing: 0.02em;
  color: #5c6f83;
  white-space: nowrap;
}
.introCompanyStyle > div > section > div:nth-child(5) h3 {
  white-space: nowrap;
}
.introCompanyStyle > div > section > div:nth-child(5) ul > li > span > span {
  font-family: var(--font-avenirNextDemi);
  padding-right: 2px;
  font-size: 16px;
  font-weight: 600;
}
.introCompanyStyle > div:nth-child(2) > section > div:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 4;
}
.introCompanyStyle > div:nth-child(2) > section > div:nth-child(2) {
  grid-column-start: 4;
  grid-column-end: 7;
}
.introCompanyStyle > div:nth-child(2) {
  margin-top: 120px;
}
.introCompanyStyle > div > section > div:nth-child(4) > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.introCompanyStyle > div > section > div:nth-child(4) > div > ul > li {
  position: relative;
  display: flex;
  padding-left: 24px;
}
.introCompanyStyle
  > div
  > section
  > div:nth-child(4)
  > div
  > ul
  > li
  > div:nth-child(1) {
  position: relative;
}
.introCompanyStyle
  > div
  > section
  > div:nth-child(4)
  > div
  > ul
  > li
  > div:nth-child(1)
  > span {
  text-align: right;
  position: absolute;
  right: 0;
  font-family: var(--font-avenirNextDemi);
  font-weight: 600;
  font-size: 11px;
  line-height: 1.25;
  top: 7px;
  margin-top: 1.5px;
}

.introCompanyStyle
  > div
  > section
  > div:nth-child(4)
  > div
  > ul
  > li
  > div:nth-child(2)
  > span:nth-child(1) {
  width: 8px;
  height: 8px;
  font-size: 0;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin: 0 8px;
}
.introCompanyStyle
  > div
  > section
  > div:nth-child(4)
  > div
  > ul
  > li
  > div:nth-child(2)
  > span:nth-child(2) {
  font-size: 11px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0.02em;
}
.introCompanyStyle > div > section > div:nth-child(6) > p,
.introCompanyStyle > div > section > div:nth-child(7) > p,
.introCompanyStyle > div > section > div:nth-child(8) > p {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.7;
  letter-spacing: 0.02em;
  color: #5c6f83;
  position: relative;
  z-index: 1;
  padding: 0 15px;
}
.introCompanyStyle > div > section > div:nth-child(6) > span,
.introCompanyStyle > div > section > div:nth-child(8) > span {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  right: 0;
}
.introCompanyStyle > div > section > div:nth-child(6) > span > div img,
.introCompanyStyle > div > section > div:nth-child(8) > span > div img {
  width: 100%;
}
.introCompanyStyle > div > section > div:nth-child(6) > span > div,
.introCompanyStyle > div > section > div:nth-child(8) > span > div {
  height: 100%;
}
.introCompanyStyle > div > section > div:nth-child(6) img,
.introCompanyStyle > div > section > div:nth-child(8) img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.introCompanyStyle > div:nth-child(2) > section > div:nth-child(1) > div {
  position: relative;
  max-width: 415px;
  width: 100%;
  margin: 23px auto 0;
}
.introCompanyStyle
  > div:nth-child(2)
  > section
  > div:nth-child(1)
  > div
  > ul:nth-child(1) {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  z-index: 1;
  padding-left: 30px;
  width: 88%;
  margin: 0 auto;
}
.introCompanyStyle
  > div:nth-child(2)
  > section
  > div:nth-child(1)
  > div
  > ul:nth-child(1)
  > li
  > span:nth-child(1) {
  font-size: 14px;
  font-weight: 600;
  font-family: var(--font-avenirNextDemi);
  text-align: center;
  display: block;
}
.introCompanyStyle
  > div:nth-child(2)
  > section
  > div:nth-child(1)
  > div
  > ul:nth-child(1)
  > li
  > span:nth-child(2) {
  position: absolute;
  font-size: 10px;
  font-weight: 600;
  font-family: var(--font-avenirNextDemi);
  color: #5c6f83;
  width: 32px;
  text-align: center;
  margin-top: 8px;
  bottom: -23px;
  left: 50%;
  transform: translateX(-50%);
}
.introCompanyStyle
  > div:nth-child(2)
  > section
  > div:nth-child(1)
  > div
  > ul:nth-child(1)
  > li {
  position: relative;
}
.introCompanyStyle
  > div:nth-child(2)
  > section
  > div:nth-child(1)
  > div
  > ul:nth-child(2) {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
}
.introCompanyStyle
  > div:nth-child(2)
  > section
  > div:nth-child(1)
  > div
  > ul:nth-child(2)
  > li {
  border-top: 1px dashed #cad1d9;
  /* height: calc(100% / 3); */
  position: relative;
  margin-left: 30px;
}
.introCompanyStyle
  > div:nth-child(2)
  > section
  > div:nth-child(1)
  > div
  > ul:nth-child(2)
  > li:last-child {
  border-top: 1px solid #cad1d9;
}
.introCompanyStyle
  > div:nth-child(2)
  > section
  > div:nth-child(1)
  > div
  > ul:nth-child(2)
  > li:last-child
  > span {
  margin-left: 12px;
}
.introCompanyStyle
  > div:nth-child(2)
  > section
  > div:nth-child(1)
  > div
  > ul:nth-child(2)
  > li
  > span {
  position: absolute;
  font-size: 10px;
  font-family: var(--font-avenirNextDemi);
  font-weight: 600;
  left: -30px;
  top: -8px;
  color: #5c6f83;
}
.introCompanyStyle > div:nth-child(2) > section > div:nth-child(2) > ul {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 auto;
}
.introCompanyStyle > div:nth-child(2) > section > div:nth-child(2) > ul > li {
  width: 50%;
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.introCompanyStyle
  > div:nth-child(2)
  > section
  > div:nth-child(2)
  > ul
  > li:nth-child(3)
  > a
  > br {
  display: none;
}
.introCompanyStyle
  > div:nth-child(2)
  > section
  > div:nth-child(2)
  > ul
  > li
  > span {
  color: #5c6f83;
  font-size: 43px;
}
.introCompanyStyle
  > div:nth-child(2)
  > section
  > div:nth-child(2)
  > ul
  > li
  > p {
  color: #6e95ff;
  padding-left: 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0.02em;
}
.introCompanyStyle .piechart {
  width: 167px;
  height: 167px;
  border-radius: 50%;
  margin-right: 6%;
  flex: none;
}

@media (max-width: 1024px) {
  .introCompanyStyle > div > section > div:nth-child(3) > div div {
    margin: 0 8px;
  }
  .introCompanyStyle
    > div
    > section
    > div:nth-child(3)
    > div
    div
    h3
    > span:nth-child(1) {
    font-size: 42px;
  }
  .introCompanyStyle
    > div
    > section
    > div:nth-child(3)
    > div
    div
    h3
    > span:nth-child(2) {
    font-size: 26px;
  }
  .introCompanyStyle > div > section > div:nth-child(4) > div {
    padding: 0 10px;
  }
  .introCompanyStyle .piechart {
    width: 150px;
    height: 150px;
    margin-right: 2px;
  }
  .introCompanyStyle > div > section > div:nth-child(5) ul {
    margin-right: 12px;
  }
  .introCompanyStyle > div > section > div:nth-child(5) > div {
    max-width: 350px;
  }
  .introCompanyStyle > div > section > div:nth-child(5) ul > li > span {
    font-size: 12px;
  }
  .introCompanyStyle
    > div
    > section
    > div:nth-child(5)
    > div
    > h3
    > span:nth-child(1) {
    font-size: 42px;
  }
  .introCompanyStyle
    > div
    > section
    > div:nth-child(5)
    > div
    > h3
    > span:nth-child(2) {
    font-size: 28px;
  }
  .introCompanyStyle
    > div
    > section
    > div:nth-child(5)
    > div
    > h3
    > span:nth-child(3) {
    font-size: 16px;
  }
  .introCompanyStyle > div > section > div:nth-child(7) h3 {
    margin-top: 0;
  }
  .introCompanyStyle > div > section > div:nth-child(7) h3 > span:nth-child(1) {
    font-size: 50px;
  }
  .introCompanyStyle > div > section > div:nth-child(7) h3 > span:nth-child(2) {
    font-size: 16px;
    padding-left: 2px;
  }
  .introCompanyStyle > div > section > div:nth-child(2) h3 > span:nth-child(1),
  .introCompanyStyle > div > section > div:nth-child(6) h3 > span:nth-child(1) {
    font-size: 74px;
  }
  .introCompanyStyle > div > section > div:nth-child(8) h3 > span:nth-child(1) {
    font-size: 50px;
  }
  .introCompanyStyle
    > div:nth-child(2)
    > section
    > div:nth-child(2)
    > ul
    > li
    > p {
    font-size: 11px;
  }
  .introCompanyStyle
    > div:nth-child(2)
    > section
    > div:nth-child(2)
    > ul
    > li:nth-child(3)
    > p
    > br {
    display: block;
  }
  .introCompanyStyle > div:nth-child(2) > section > div:nth-child(1) > div {
    width: 90%;
  }
  .introCompanyStyle > div > section > div:nth-child(6) > p,
  .introCompanyStyle > div > section > div:nth-child(7) > p,
  .introCompanyStyle > div > section > div:nth-child(8) > p {
    padding: 0 8px;
  }
}

@media (max-width: 768px) {
  .introCompanyStyle > div > section > div {
    border-radius: 3px;
  }
  .introCompanyStyle > div > section {
    grid-gap: 16px;
  }
  .introCompanyStyle > div > section > div {
    height: 184px;
  }
  .introCompanyStyle > div > section > div:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .introCompanyStyle > div > section > div:nth-child(2) {
    grid-column-start: 4;
    grid-column-end: 7;
  }
  .introCompanyStyle > div > section > div:nth-child(3),
  .introCompanyStyle > div > section > div:nth-child(4),
  .introCompanyStyle > div > section > div:nth-child(5),
  .introCompanyStyle > div > section > div:nth-child(6),
  .introCompanyStyle > div > section > div:nth-child(7),
  .introCompanyStyle > div > section > div:nth-child(8) {
    grid-column-start: 1;
    grid-column-end: 7;
  }
  .introCompanyStyle > div > section > div h6 {
    font-size: 11px;
    padding: 10px 12px 0;
    text-align: left;
  }
  .introCompanyStyle > div > section > div h6 > span {
    font-size: 8px;
  }
  .introCompanyStyle > div > section > div h3 > span:nth-child(2) {
    font-size: 14px;
  }
  .introCompanyStyle > div > section > div:nth-child(1) img {
    max-width: 82px;
    width: 100%;
    margin-top: -7px;
  }
  .introCompanyStyle > div > section > div:nth-child(1) h3 > span:nth-child(1) {
    font-size: 40px;
  }
  .introCompanyStyle > div > section > div:nth-child(1) h3 > span:nth-child(2) {
    font-size: 14px;
  }
  .introCompanyStyle > div > section > div:nth-child(2) h3 > span:nth-child(1) {
    font-size: 48px;
  }
  .introCompanyStyle > div > section > div h3 > span:nth-child(2) {
    font-size: 16px;
    padding-left: 2px;
  }
  .introCompanyStyle > div > section > div:nth-child(2) img {
    max-width: 98px;
    width: 100%;
    margin-top: -12px;
  }
  .introCompanyStyle > div > section > div > small {
    padding: 10px 12px;
  }
  .introCompanyStyle
    > div
    > section
    > div:nth-child(3)
    > div
    div
    h3
    > span:nth-child(1) {
    font-size: 48px;
  }
  .introCompanyStyle
    > div
    > section
    > div:nth-child(3)
    > div
    div
    h3
    > span:nth-child(2) {
    font-size: 32px;
  }
  .introCompanyStyle
    > div
    > section
    > div:nth-child(3)
    > div
    div
    h3
    > span:nth-child(3) {
    font-size: 16px;
    padding-left: 2px;
  }
  .introCompanyStyle > div > section > div:nth-child(3) > div div > h3 + img {
    width: 94px;
  }
  .introCompanyStyle
    > div
    > section
    > div:nth-child(3)
    > div
    div
    > h3
    + img
    + span
    img {
    width: 69px;
  }
  .introCompanyStyle > div > section > div:nth-child(3) > div div {
    margin: 0 15px;
    position: relative;
  }
  .introCompanyStyle > div > section > div:nth-child(3) > div div h3 {
    margin-top: -14px;
    margin-bottom: -16px;
  }
  .introCompanyStyle > div > section > div:nth-child(3) > div div > p {
    font-size: 10px;
    position: absolute;
    bottom: 25px;
    width: 100%;
    display: block;
  }
  .introCompanyStyle
    > div
    > section
    > div:nth-child(3)
    > div
    div:nth-child(1)
    > p {
    text-align: left;
    margin-left: 10px;
  }
  .introCompanyStyle
    > div
    > section
    > div:nth-child(3)
    > div
    div:nth-child(2)
    > p {
    text-align: right;
    margin-left: -10px;
  }
  .introCompanyStyle > div > section > div:nth-child(4) {
    height: auto;
  }
  .introCompanyStyle > div > section > div:nth-child(4) > div {
    flex-direction: column;
  }
  .introCompanyStyle .piechart {
    width: 128px;
    height: 128px;
    margin-bottom: 16px;
  }
  .introCompanyStyle > div > section > div:nth-child(4) > div {
    padding-bottom: 36px;
  }
  .introCompanyStyle
    > div
    > section
    > div:nth-child(4)
    > div
    > ul
    > li
    > div:nth-child(1)
    > span {
    font-size: 10px;
  }
  .introCompanyStyle
    > div
    > section
    > div:nth-child(4)
    > div
    > ul
    > li
    > div:nth-child(2)
    > span:nth-child(2) {
    font-size: 10px;
  }
  .introCompanyStyle > div > section > div:nth-child(5) ul > li > span {
    font-size: 10px;
    padding-right: 8px;
  }
  .introCompanyStyle > div > section > div:nth-child(5) ul > li > span > span {
    font-size: 14px;
  }
  .introCompanyStyle
    > div
    > section
    > div:nth-child(5)
    ul
    > li:not(:last-child) {
    margin-bottom: 2px;
  }
  .introCompanyStyle > div > section > div:nth-child(5) > div {
    margin-top: 0;
    max-width: 302px;
  }
  .introCompanyStyle
    > div
    > section
    > div:nth-child(5)
    > div
    > h3
    > span:nth-child(1) {
    font-size: 48px;
  }
  .introCompanyStyle
    > div
    > section
    > div:nth-child(5)
    > div
    > h3
    > span:nth-child(2) {
    font-size: 32px;
  }
  .introCompanyStyle > div > section > div:nth-child(5) ul {
    margin-right: 20px;
  }
  .introCompanyStyle > div > section > div:nth-child(6) h3 > span:nth-child(1),
  .introCompanyStyle > div > section > div:nth-child(7) h3 > span:nth-child(1),
  .introCompanyStyle > div > section > div:nth-child(8) h3 > span:nth-child(2) {
    font-size: 72px;
    line-height: 1.4;
  }
  .introCompanyStyle > div > section > div:nth-child(6) h3 > span:nth-child(2),
  .introCompanyStyle > div > section > div:nth-child(8) h3 > span:nth-child(3) {
    font-size: 24px;
  }
  .introCompanyStyle > div > section > div:nth-child(8) h3 > span:nth-child(3) {
    padding-left: 4px;
  }
  .introCompanyStyle > div > section > div:nth-child(6) > p,
  .introCompanyStyle > div > section > div:nth-child(7) > p,
  .introCompanyStyle > div > section > div:nth-child(8) > p {
    font-size: 11px;
  }
  .introCompanyStyle > div > section > div:nth-child(7) > p br:first-child,
  .introCompanyStyle > div > section > div:nth-child(8) > p br:first-child {
    display: block;
  }
  .introCompanyStyle > div > section > div:nth-child(6) > h3 {
    vertical-align: middle;
    margin-top: 24px;
    width: 32%;
    float: left;
    text-align: right;
  }
  .introCompanyStyle > div > section > div:nth-child(6) > h3 + p {
    vertical-align: middle;
    text-align: left;
    margin-top: 32px;
    float: right;
    width: 68%;
    text-align: left;
    padding: 0 5px 0 15px;
  }
  .introCompanyStyle > div > section > div:nth-child(6) {
    text-align: center;
  }
  .introCompanyStyle > div > section > div:nth-child(7),
  .introCompanyStyle > div > section > div:nth-child(8) {
    height: 327px;
  }
  .introCompanyStyle > div > section > div:nth-child(7) h3 {
    margin-top: 61px;
    margin-bottom: 0;
  }
  .introCompanyStyle > div > section > div:nth-child(8) h3 {
    margin-top: 51px;
    margin-bottom: -10px;
  }
  .introCompanyStyle > div > section > div:nth-child(7) h3 > span:nth-child(1),
  .introCompanyStyle > div > section > div:nth-child(8) h3 > span:nth-child(1) {
    font-size: 72px;
  }
  .introCompanyStyle > div > section > div:nth-child(7) h3 > span:nth-child(2),
  .introCompanyStyle > div > section > div:nth-child(8) h3 > span:nth-child(2) {
    font-size: 16px;
  }
  .introCompanyStyle > div > section > div:nth-child(7) p,
  .introCompanyStyle > div > section > div:nth-child(8) p {
    padding-bottom: 70px;
  }
  .introCompanyStyle > div:nth-child(2) {
    margin-top: 80px;
  }
  .introCompanyStyle > div:nth-child(2) > section > div:nth-child(1),
  .introCompanyStyle > div:nth-child(2) > section > div:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 7;
  }
  .introCompanyStyle > div:nth-child(2) > section > div:nth-child(1) {
    height: 327px;
  }
  .introCompanyStyle > div:nth-child(2) > section > div:nth-child(2) > ul > li {
    margin-top: 4px;
  }
  .introCompanyStyle > div:nth-child(2) > section > div:nth-child(2) > ul {
    margin-top: 18px;
  }
  .introCompanyStyle
    > div:nth-child(2)
    > section
    > div:nth-child(2)
    > ul
    > li
    > span {
    font-size: 27px;
  }
  .introCompanyStyle > div:nth-child(2) > section > div:nth-child(1) > div {
    width: 86%;
  }
  .introCompanyStyle
    > div:nth-child(2)
    > section
    > div:nth-child(1)
    > div
    > ul:nth-child(1)
    > li
    > span:nth-child(1) {
    font-size: 12px;
  }
  .introCompanyStyle
    > div:nth-child(2)
    > section
    > div:nth-child(1)
    > div
    > ul:nth-child(1)
    > li
    > span:nth-child(2) {
    font-size: 9px;
  }
  .introCompanyStyle > div:nth-child(2) > section > div:nth-child(1) > div {
    margin: 32px auto 0;
  }
}
@media (max-width: 349px) {
  .introCompanyStyle
    > div
    > section
    > div:nth-child(5)
    > div
    > h3
    > span:nth-child(1) {
    font-size: 28px;
  }
  .introCompanyStyle
    > div
    > section
    > div:nth-child(5)
    > div
    > h3
    > span:nth-child(2) {
    font-size: 20px;
  }
  .introCompanyStyle
    > div
    > section
    > div:nth-child(5)
    > div
    > h3
    > span:nth-child(3) {
    font-size: 12px;
  }
  .introCompanyStyle > div > section > div:nth-child(5) ul {
    margin-right: 5px;
  }
}

/* howworkStyle */

.howworkStyle > div > section {
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 32px;
}
.howworkStyle > div > section > div {
  border-radius: 8px;
  background-color: #f2f7fd;
  position: relative;
  height: 320px;
  overflow: hidden;
}
.howworkStyle > div > section > div h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.65;
  letter-spacing: 0.02em;
  color: #5c6f83;
}
.howworkStyle > div > section > div h6:nth-child(1) {
  padding: 24px 28px 0;
}
.howworkStyle > div > section > div h3 {
  text-align: center;
  font-weight: 600;
  position: relative;
}
.howworkStyle > div > section > div h3 > h6 {
  position: absolute;
  top: 0px;
}
.howworkStyle > div > section > div h3 + img {
  margin: 0 auto;
}
.howworkStyle > div > section > div div + img {
  margin: 0 auto;
}
.howworkStyle > div > section > div > small {
  font-size: 10px;
  font-weight: 400;
  line-height: 1.7;
  letter-spacing: 0.02em;
  left: 16px;
  bottom: 12px;
  position: absolute;
  color: #5c6f83;
  padding-right: 12px;
}
.howworkStyle > div > section > div:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 5;
}
.howworkStyle > div > section > div:nth-child(2) {
  grid-column-start: 5;
  grid-column-end: 7;
}
.howworkStyle > div > section > div:nth-child(3) {
  grid-column-start: 1;
  grid-column-end: 4;
}
.howworkStyle > div > section > div:nth-child(4) {
  grid-column-start: 4;
  grid-column-end: 7;
}
.howworkStyle > div > section > div:nth-child(5) {
  grid-column-start: 1;
  grid-column-end: 3;
}
.howworkStyle > div > section > div:nth-child(6) {
  grid-column-start: 3;
  grid-column-end: 7;
}
.howworkStyle > div > section > div:nth-child(7) {
  grid-column-start: 1;
  grid-column-end: 4;
}
.howworkStyle > div > section > div:nth-child(8) {
  grid-column-start: 4;
  grid-column-end: 7;
}
.howworkStyle > div > section > div:nth-child(9) {
  grid-column-start: 1;
  grid-column-end: 4;
}
.howworkStyle > div > section > div:nth-child(10) {
  grid-column-start: 4;
  grid-column-end: 7;
}
.howworkStyle > div > section > div span.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 300;
}
.howworkStyle > div > section > div:nth-child(1) h3 {
  margin-top: 19px;
}
.howworkStyle > div > section > div:nth-child(1) > div > div {
  max-width: 363px;
  margin: 0 auto;
}
.howworkStyle > div > section > div:nth-child(1) > div > div img {
  margin-top: -11px;
}
.howworkStyle > div > section > div:nth-child(1) > div > div > div {
  display: flex;
  justify-content: space-between;
  margin-right: -30px;
}
.howworkStyle > div > section > div:nth-child(1) h3 > div {
  font-size: 88px;
  font-weight: 600;
  line-height: 1.7;
  letter-spacing: -0.03em;
  font-family: var(--font-avenirNextDemi);
  color: #6e95ff;
  position: relative;
  display: inline;
}
.howworkStyle > div > section > div:nth-child(1) h3 > div span:nth-child(1) {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7;
  letter-spacing: 0.02em;
  color: #5c6f83;
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-family: var(--notosanjp);
}
.howworkStyle > div > section > div:nth-child(1) h3 > div span:nth-child(2) {
  font-size: 88px;
  font-weight: 600;
  line-height: 1.7;
  letter-spacing: -0.03em;
  font-family: var(--font-avenirNextDemi);
  color: #6e95ff;
}
.howworkStyle > div > section > div:nth-child(1) h3 > span {
  font-size: 24px;
  font-weight: 600;
  font-family: var(--notosanjp);
  color: #5c6f83;
  padding-left: 8px;
}
.howworkStyle > div > section > div:nth-child(2) h3 > span:nth-child(1),
.howworkStyle > div > section > div:nth-child(2) h3 > span:nth-child(4) {
  font-size: 28px;
  color: #5c6f83;
  font-weight: 500;
  letter-spacing: 0.02em;
  white-space: nowrap;
}
.howworkStyle > div > section > div:nth-child(2) h3 > span:nth-child(2) {
  font-size: 72px;
  font-weight: 600;
  line-height: 1.7;
  letter-spacing: -0.03em;
  font-family: var(--font-avenirNextDemi);
  color: #6e95ff;
  margin-left: 4px;
}
.howworkStyle > div > section > div:nth-child(2) h3 > span:nth-child(3) {
  font-size: 64px;
  font-weight: 600;
  line-height: 1.7;
  letter-spacing: -0.03em;
  font-family: var(--font-avenirNextDemi);
  color: #6e95ff;
  margin-right: 4px;
}
.howworkStyle > div > section > div:nth-child(2) img {
  margin: 15px auto 0;
}
.howworkStyle > div > section > div:nth-child(3) {
  position: relative;
}
.howworkStyle > div > section > div:nth-child(3) > img {
  position: absolute;
  bottom: 0;
  left: 0;
}
.howworkStyle > div > section > div:nth-child(3) > span > div span {
  position: absolute;
  bottom: -22px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 142px;
  color: #5c6f83;
}
.howworkStyle > div > section > div:nth-child(3) h3 {
  padding-bottom: 85px;
}
.howworkStyle > div > section > div:nth-child(3) h3 > span:nth-child(1) {
  font-size: 110px;
  font-weight: 600;
  line-height: 1.7;
  letter-spacing: -0.05em;
  font-family: var(--font-avenirNextDemi);
  color: #6e95ff;
  padding-right: 4px;
}
.howworkStyle > div > section > div:nth-child(3) h3 > span:nth-child(2) {
  font-size: 40px;
  font-weight: 500;
  font-family: var(--notosanjp);
  color: #5c6f83;
  letter-spacing: 0.02em;
}
.howworkStyle > div > section > div:nth-child(3) > div div > span {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.7;
  color: #5c6f83;
  text-align: center;
  margin-top: -5px;
  display: block;
}

.howworkStyle > div > section > div:nth-child(4) > div > ul {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 68px 10px 0;
}
.howworkStyle > div > section > div:nth-child(4) > div > ul li {
  list-style: none;
  text-align: center;
}

.howworkStyle > div > section > div:nth-child(4) div:first-child {
  display: flex;
}
.howworkStyle
  > div
  > section
  > div:nth-child(4)
  > div
  > ul
  > li:not(:last-child) {
  margin-right: 30px;
}
.howworkStyle
  > div
  > section
  > div:nth-child(4)
  > div
  > ul
  > li
  span:nth-child(1) {
  color: #5c6f83;
  font-size: 56px;
  background-color: white;
  padding: 20px;
}
.howworkStyle
  > div
  > section
  > div:nth-child(4)
  > div
  > ul
  li
  span:nth-child(2) {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.7;
  letter-spacing: 0.02em;
  color: #6e95ff;
  font-family: var(--notosanjp);
  display: block;
  margin-top: 12px;
}
.howworkStyle > div > section > div:nth-child(5) > div {
  padding: 50px 0 70px;
}
.howworkStyle > div > section > div:nth-child(5) h3 > span:nth-child(1) {
  font-size: 88px;
  font-weight: 600;
  line-height: 1.7;
  letter-spacing: -0.03em;
  font-family: var(--font-avenirNextDemi);
  color: #6e95ff;
}
.howworkStyle > div > section > div:nth-child(5) h3 > span:nth-child(2) {
  font-size: 28px;
  font-weight: 500;
  font-family: var(--notosanjp);
  color: #5c6f83;
  letter-spacing: 0.02em;
}
.howworkStyle > div > section > div:nth-child(5) > span div,
.howworkStyle > div > section > div:nth-child(8) > span div {
  height: 100%;
}

.howworkStyle > div > section > div:nth-child(5) > span,
.howworkStyle > div > section > div:nth-child(8) > span {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
}
.howworkStyle > div > section > div:nth-child(5) img {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 0;
}
.howworkStyle > div > section > div:nth-child(6) h3 {
  text-align: left;
  padding-left: 108px;
}
.howworkStyle > div > section > div:nth-child(6) h3 > span:nth-child(2) {
  font-size: 110px;
  font-weight: 600;
  line-height: 1.7;
  letter-spacing: -0.05em;
  font-family: var(--font-avenirNextDemi);
  color: #6e95ff;
}
.howworkStyle > div > section > div:nth-child(6) h3 > span:nth-child(3) {
  font-size: 72px;
  font-weight: 600;
  line-height: 1.7;
  letter-spacing: -0.03em;
  font-family: var(--font-avenirNextDemi);
  color: #6e95ff;
}
.howworkStyle > div > section > div:nth-child(6) h3 > span:nth-child(1),
.howworkStyle > div > section > div:nth-child(6) h3 > span:nth-child(4) {
  font-size: 40px;
  font-weight: 500;
  font-family: var(--notosanjp);
  color: #5c6f83;
  letter-spacing: 0.02em;
}

.howworkStyle > div > section > div:nth-child(6) > span {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
}
.howworkStyle > div > section > div:nth-child(6) > span div {
  height: 100%;
}
.howworkStyle > div > section > div:nth-child(6) img {
  position: absolute;
  bottom: 0;
  right: 30px;
  z-index: 0;
}
.howworkStyle > div > section > div:nth-child(7) > div > ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 48px 20px 55px;
  max-width: 380px;
  margin: 0 auto;
}
.howworkStyle > div > section > div:nth-child(7) > div > ul li {
  list-style: none;
  background-color: white;
  border-radius: 20px;
  padding: 7px 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 17px;
}

.howworkStyle > div > section > div:nth-child(7) div:first-child {
  display: flex;
}
.howworkStyle
  > div
  > section
  > div:nth-child(7)
  > div
  > ul
  > li:not(:nth-child(4n)) {
  margin-right: 14px;
}
.howworkStyle > div > section > div:nth-child(7) > div > ul > li span {
  color: #a0a0a0;
  font-size: 21px;
  font-family: var(--font-avenirNextDemi);
  padding-left: 7px;
  line-height: 1;
}
.howworkStyle > div > section > div:nth-child(8) h3 {
  padding-top: 30px;
}
.howworkStyle > div > section > div:nth-child(8) h3 > span:nth-child(1) {
  font-size: 88px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: -0.03em;
  font-family: var(--font-avenirNextDemi);
  color: #6e95ff;
  margin-right: 4px;
}

.howworkStyle > div > section > div:nth-child(8) h3 > span:nth-child(2) {
  font-size: 28px;
  font-weight: 500;
  font-family: var(--notosanjp);
  color: #5c6f83;
  letter-spacing: 0.02em;
}

.howworkStyle > div > section > div:nth-child(8) h3 > span:nth-child(3) {
  font-size: 12px;
  font-weight: 500;
  font-family: var(--notosanjp);
  color: #5c6f83;
  letter-spacing: 0.02em;
  line-height: 1.7;
  display: block;
}
.howworkStyle > div > section > div:nth-child(8) img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}
.howworkStyle > div > section > div:nth-child(9) > ul,
.howworkStyle > div > section > div:nth-child(10) > ul {
  display: flex;
  max-width: 450px;
  padding: 36px 13px 0;
  margin: 0 auto;
}
.howworkStyle > div > section > div:nth-child(9) > ul li,
.howworkStyle > div > section > div:nth-child(10) > ul li {
  width: calc((100% - 45px) / 2);
}
.howworkStyle > div > section > div:nth-child(9) > ul li:first-child,
.howworkStyle > div > section > div:nth-child(10) > ul li:first-child {
  margin-right: 45px;
}
.howworkStyle > div > section > div:nth-child(9) > ul li > span:first-child,
.howworkStyle > div > section > div:nth-child(10) > ul li > span:first-child {
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-family: var(--notosanjp);
  color: #6e95ff;
  margin-bottom: 14px;
  display: block;
}
.howworkStyle > div > section > div:nth-child(9) > ul li > span:nth-child(2),
.howworkStyle > div > section > div:nth-child(10) > ul li > span:nth-child(2),
.howworkStyle > div > section > div:nth-child(9) > ul li > span:nth-child(3),
.howworkStyle > div > section > div:nth-child(10) > ul li > span:nth-child(3) {
  font-size: 20px;
  line-height: 1.7;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-family: var(--notosanjp);
  color: #242a32;
  margin-bottom: 12px;
  display: flex;
  align-items: flex-start;
}
.howworkStyle
  > div
  > section
  > div:nth-child(9)
  > ul
  li
  > span:nth-child(2)
  img,
.howworkStyle
  > div
  > section
  > div:nth-child(10)
  > ul
  li
  > span:nth-child(2)
  img,
.howworkStyle
  > div
  > section
  > div:nth-child(9)
  > ul
  li
  > span:nth-child(3)
  img,
.howworkStyle
  > div
  > section
  > div:nth-child(10)
  > ul
  li
  > span:nth-child(3)
  img {
  margin-right: 16px;
}
.howworkStyle > div > section > div:nth-child(9) > ul li > p,
.howworkStyle > div > section > div:nth-child(10) > ul li > p {
  font-size: 12px;
  line-height: 1.7;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-family: var(--notosanjp);
  color: #5c6f83;
  margin-top: 14px;
  word-break: break-all;
}
.howworkStyle > div:nth-child(2) > ul {
  display: flex;
  padding-top: 32px;
}
.howworkStyle > div:nth-child(2) > ul li {
  height: 480px;
  background: #f2f7fd;
  width: calc((100% - 32px) / 2);
  padding: 40px;
  border-radius: 8px;
}
.howworkStyle > div:nth-child(2) > ul li > div:nth-child(2) {
  position: relative;
  height: calc(100% - 60px);
}
.howworkStyle > div:nth-child(2) > ul li:first-child {
  margin-right: 32px;
}
.howworkStyle > div:nth-child(2) > ul li > div:nth-child(1) {
  font-weight: 500;
  font-family: var(--notosanjp);
  color: #242a32;
  line-height: 1.7;
  letter-spacing: 0.02em;
  text-align: center;
  padding-bottom: 32px;
  display: block;
  font-size: 20px;
}
.howworkStyle > div:nth-child(2) > ul li > div:nth-child(2) > div:nth-child(1),
.howworkStyle > div:nth-child(2) > ul li > div:nth-child(2) > div:nth-child(3),
.howworkStyle > div:nth-child(2) > ul li > div:nth-child(2) > div:nth-child(4),
.howworkStyle > div:nth-child(2) > ul li > div:nth-child(2) > div:nth-child(2) {
  font-weight: 500;
  font-family: var(--notosanjp);
  color: #6e95ff;
  line-height: 1.5;
  letter-spacing: 0.02em;
  padding: 24px 20px;
  background: white;
  border-radius: 12px;
  text-align: center;
  filter: drop-shadow(0px 0px 40px rgba(98, 130, 151, 0.1));
}
.howworkStyle
  > div:nth-child(2)
  > ul
  li
  > div:nth-child(2)
  > div:nth-child(1)
  > img,
.howworkStyle
  > div:nth-child(2)
  > ul
  li
  > div:nth-child(2)
  > div:nth-child(3)
  > img {
  position: absolute;
  bottom: -13px;
  left: 33px;
}
.howworkStyle
  > div:nth-child(2)
  > ul
  li
  > div:nth-child(2)
  > div:nth-child(2)
  > img,
.howworkStyle
  > div:nth-child(2)
  > ul
  li
  > div:nth-child(2)
  > div:nth-child(4)
  > img {
  position: absolute;
  bottom: -13px;
  right: 33px;
}

.howworkStyle > div:nth-child(2) > ul li > div:nth-child(2) > div:nth-child(1) {
  position: absolute;
  top: 0;
  left: 16px;
  min-width: 220px;
  z-index: 2;
}
.howworkStyle > div:nth-child(2) > ul li > div:nth-child(2) > div:nth-child(2) {
  position: absolute;
  top: 27px;
  right: 7px;
  min-width: 240px;
  z-index: 1;
}

.howworkStyle
  > div:nth-child(2)
  > ul
  li:nth-child(2)
  > div:nth-child(2)
  > div:nth-child(2) {
  min-width: 201px;
  top: 46px;
}
.howworkStyle > div:nth-child(2) > ul li > div:nth-child(2) > div:nth-child(3) {
  position: absolute;
  bottom: 48px;
  left: 19px;
  min-width: 240px;
  z-index: 1;
}
.howworkStyle
  > div:nth-child(2)
  > ul
  li:nth-child(2)
  > div:nth-child(2)
  > div:nth-child(3) {
  left: 0;
  bottom: 64px;
}
.howworkStyle > div:nth-child(2) > ul li > div:nth-child(2) > div:nth-child(4) {
  position: absolute;
  bottom: 17px;
  right: 7px;
  min-width: 213px;
  z-index: 2;
}
.howworkStyle
  > div:nth-child(2)
  > ul
  li:nth-child(2)
  > div:nth-child(2)
  > div:nth-child(4) {
  min-width: 240px;
}
.howworkStyle > div:nth-child(2) > ul li:nth-child(2) > div:nth-child(2) div {
  color: #5c6f83;
}

@media (max-width: 1150px) {
  .howworkStyle
    > div:nth-child(2)
    > ul
    li
    > div:nth-child(2)
    > div:nth-child(1),
  .howworkStyle
    > div:nth-child(2)
    > ul
    li
    > div:nth-child(2)
    > div:nth-child(3),
  .howworkStyle
    > div:nth-child(2)
    > ul
    li
    > div:nth-child(2)
    > div:nth-child(4),
  .howworkStyle
    > div:nth-child(2)
    > ul
    li
    > div:nth-child(2)
    > div:nth-child(2) {
    font-size: 12px;
    padding: 12px 10px;
    border-radius: 8px;
    position: absolute;
    bottom: unset;
    left: unset;
    top: unset;
    right: unset;
  }
  .howworkStyle
    > div:nth-child(2)
    > ul
    li
    > div:nth-child(2)
    > div:nth-child(1)
    > img,
  .howworkStyle
    > div:nth-child(2)
    > ul
    li
    > div:nth-child(2)
    > div:nth-child(3)
    > img {
    position: absolute;
    bottom: -13px;
    left: 33px;
  }
  .howworkStyle
    > div:nth-child(2)
    > ul
    li
    > div:nth-child(2)
    > div:nth-child(2)
    > img,
  .howworkStyle
    > div:nth-child(2)
    > ul
    li
    > div:nth-child(2)
    > div:nth-child(4)
    > img {
    position: absolute;
    bottom: -13px;
    right: 33px;
  }

  .howworkStyle
    > div:nth-child(2)
    > ul
    li
    > div:nth-child(2)
    > div:nth-child(1) {
    min-width: 168px;
    z-index: 2;
    top: 0;
    right: calc(50% - 34px);
  }
  .howworkStyle
    > div:nth-child(2)
    > ul
    li
    > div:nth-child(2)
    > div:nth-child(2) {
    min-width: 160px;
    z-index: 1;
    top: 70px;
    left: calc(50% - 22px);
  }

  .howworkStyle
    > div:nth-child(2)
    > ul
    li:nth-child(2)
    > div:nth-child(2)
    > div:nth-child(2) {
    top: 82px;
    min-width: 160px;
  }
  .howworkStyle
    > div:nth-child(2)
    > ul
    li
    > div:nth-child(2)
    > div:nth-child(3) {
    top: 161px;
    right: calc(50% - 20px);
    min-width: 154px;
  }
  .howworkStyle
    > div:nth-child(2)
    > ul
    li:nth-child(2)
    > div:nth-child(2)
    > div:nth-child(3) {
    min-width: 154px;
    left: unset;
    bottom: unset;
  }
  .howworkStyle
    > div:nth-child(2)
    > ul
    li
    > div:nth-child(2)
    > div:nth-child(4) {
    top: 205px;
    left: calc(50% + 6px);
    min-width: 131px;
    z-index: 2;
  }
  .howworkStyle
    > div:nth-child(2)
    > ul
    li:nth-child(2)
    > div:nth-child(2)
    > div:nth-child(4) {
    min-width: 131px;
  }
}
@media (max-width: 1024px) {
  .howworkStyle > div > section > div:nth-child(2) h3 > span:nth-child(1),
  .howworkStyle > div > section > div:nth-child(2) h3 > span:nth-child(4) {
    font-size: 20px;
  }
  .howworkStyle > div > section > div:nth-child(2) h3 > span:nth-child(2) {
    font-size: 66px;
  }
  .howworkStyle > div > section > div:nth-child(2) h3 > span:nth-child(3) {
    font-size: 42px;
  }
  .howworkStyle
    > div
    > section
    > div:nth-child(4)
    > div
    > ul
    > li:not(:last-child) {
    margin-right: 16px;
  }
}
@media (max-width: 768px) {
  .howworkStyle > div > section > div {
    border-radius: 3px;
  }
  .howworkStyle > div > section > div:nth-child(1),
  .howworkStyle > div > section > div:nth-child(4),
  .howworkStyle > div > section > div:nth-child(5),
  .howworkStyle > div > section > div:nth-child(6),
  .howworkStyle > div > section > div:nth-child(7),
  .howworkStyle > div > section > div:nth-child(8),
  .howworkStyle > div > section > div:nth-child(9),
  .howworkStyle > div > section > div:nth-child(10) {
    grid-column-start: 1;
    grid-column-end: 7;
  }
  .howworkStyle > div > section > div:nth-child(2) {
    grid-column-start: 1;
    grid-column-end: 4;
  }
  .howworkStyle > div > section > div:nth-child(3) {
    grid-column-start: 4;
    grid-column-end: 7;
  }
  .howworkStyle > div > section {
    grid-gap: 16px;
  }
  .howworkStyle > div > section > div {
    height: auto;
  }
  .howworkStyle > div > section > div:nth-child(1) {
    height: 327px;
  }
  .howworkStyle > div > section > div h6 {
    font-size: 11px;
    color: #5c6f83;
  }
  .howworkStyle > div > section > div h6:nth-child(1) {
    padding: 10px 12px 0;
  }
  .howworkStyle > div > section > div:nth-child(1) h3 > div span:nth-child(2) {
    font-size: 72px;
  }
  .howworkStyle > div > section > div:nth-child(1) > div > div {
    max-width: 230px;
    margin: 0 auto;
  }
  .howworkStyle > div > section > div:nth-child(2) h3 > span:nth-child(1),
  .howworkStyle > div > section > div:nth-child(2) h3 > span:nth-child(4) {
    font-size: 16px;
  }
  .howworkStyle > div > section > div:nth-child(2) h3 > span:nth-child(2) {
    font-size: 48px;
  }
  .howworkStyle > div > section > div:nth-child(2) h3 > span:nth-child(3) {
    font-size: 32px;
  }
  .howworkStyle > div > section > div:nth-child(1) h3 > div span:nth-child(1) {
    font-size: 12px;
    top: 0;
  }
  .howworkStyle > div > section > div:nth-child(2) h3 {
    margin-top: 21px;
  }
  .howworkStyle > div > section > div:nth-child(3) h3 > span:nth-child(1) {
    font-size: 48px;
  }
  .howworkStyle > div > section > div:nth-child(3) h3 > span:nth-child(2) {
    font-size: 16px;
  }
  .howworkStyle > div:nth-child(2) > ul {
    display: block;
  }
  .howworkStyle > div:nth-child(2) > ul li {
    height: 400px;
    width: 100%;
    padding: 28px;
    border-radius: 3px;
  }
  .howworkStyle > div:nth-child(2) > ul li > div:nth-child(2) {
    position: relative;
    height: calc(100% - 47px);
  }
  .howworkStyle > div:nth-child(2) > ul li:first-child {
    margin-right: 0;
    margin-bottom: 16px;
  }
  .howworkStyle > div:nth-child(2) > ul li > div:nth-child(1) {
    font-size: 16px;
  }

  .howworkStyle > div > section > div:nth-child(3) > span > div span {
    font-size: 54px;
    bottom: 40px;
  }
  .howworkStyle > div > section > div:nth-child(4) > div > ul {
    max-width: 247px;
    margin: 22px auto 31px;
  }
  .howworkStyle
    > div
    > section
    > div:nth-child(4)
    > div
    > ul
    > li
    span:nth-child(1) {
    padding: 11px;
    font-size: 32px;
  }
  .howworkStyle > div > section > div:nth-child(4) > small {
    display: none;
  }

  .howworkStyle
    > div
    > section
    > div:nth-child(4)
    > div
    > ul
    li
    span:nth-child(2) {
    font-size: 11px;
  }
  .howworkStyle > div > section > div:nth-child(5) img {
    width: 100%;
  }
  .howworkStyle > div > section > div:nth-child(5) > div {
    padding: 10px 0 50px;
  }
  .howworkStyle > div > section > div:nth-child(5) h3 > span:nth-child(1) {
    font-size: 72px;
  }
  .howworkStyle > div > section > div:nth-child(5) h3 > span:nth-child(2) {
    font-size: 24px;
  }
  .howworkStyle > div > section > div:nth-child(6) h3 > span:nth-child(2) {
    font-size: 72px;
  }
  .howworkStyle > div > section > div:nth-child(6) h3 > span:nth-child(3) {
    font-size: 48px;
  }
  .howworkStyle > div > section > div:nth-child(6) h3 > span:nth-child(1),
  .howworkStyle > div > section > div:nth-child(6) h3 > span:nth-child(4) {
    font-size: 24px;
  }
  .howworkStyle > div > section > div:nth-child(6),
  .howworkStyle > div > section > div:nth-child(8),
  .howworkStyle > div > section > div:nth-child(9),
  .howworkStyle > div > section > div:nth-child(10) {
    height: 327px;
    overflow: hidden;
  }
  .howworkStyle > div > section > div:nth-child(6) h3 {
    padding-top: 75px;
    text-align: center;
    padding-left: 0;
    margin-left: -110px;
  }
  .howworkStyle > div > section > div:nth-child(6) img {
    max-width: 176px;
    right: 0;
    bottom: 73px;
  }
  .howworkStyle > div > section > div:nth-child(7) > div > ul {
    padding: 24px 0 31px;
    max-width: 220px;
  }
  .howworkStyle
    > div
    > section
    > div:nth-child(7)
    > div
    > ul
    > li:not(:nth-child(4n)) {
    margin-right: 8px;
  }
  .howworkStyle > div > section > div:nth-child(7) > div > ul li {
    padding: 4px 8px;
    margin-bottom: 10px;
  }
  .howworkStyle > div > section > div:nth-child(7) > div > ul li > img {
    max-width: 17px;
    width: 100%;
  }
  .howworkStyle > div > section > div:nth-child(7) > div > ul > li span {
    font-size: 13px;
  }
  .howworkStyle > div > section > div:nth-child(8) h3 > span:nth-child(1) {
    font-size: 72px;
  }
  .howworkStyle > div > section > div:nth-child(8) h3 > span:nth-child(2) {
    font-size: 24px;
  }
  .howworkStyle > div > section > div:nth-child(8) h3 > span:nth-child(3) {
    font-size: 11px;
  }
  .howworkStyle > div > section > div:nth-child(8) h3 {
    padding-top: 56px;
  }
  .howworkStyle > div > section > div:nth-child(9) > ul,
  .howworkStyle > div > section > div:nth-child(10) > ul {
    padding: 30px 32px 0;
    display: block;
  }
  .howworkStyle > div > section > div:nth-child(9) > ul li > span:first-child,
  .howworkStyle > div > section > div:nth-child(10) > ul li > span:first-child {
    font-size: 13px;
    margin-bottom: 8px;
  }
  .howworkStyle > div > section > div:nth-child(9) > ul li > span:nth-child(2),
  .howworkStyle
    > div
    > section
    > div:nth-child(10)
    > ul
    li
    > span:nth-child(2) {
    font-size: 16px;
    float: left;
    margin-right: 16px;
  }
  .howworkStyle > div > section > div:nth-child(9) > ul li > span:nth-child(3),
  .howworkStyle
    > div
    > section
    > div:nth-child(10)
    > ul
    li
    > span:nth-child(3) {
    font-size: 16px;
  }
  .howworkStyle
    > div
    > section
    > div:nth-child(9)
    > ul
    li
    > span:nth-child(2)
    img,
  .howworkStyle
    > div
    > section
    > div:nth-child(10)
    > ul
    li
    > span:nth-child(2)
    img,
  .howworkStyle
    > div
    > section
    > div:nth-child(9)
    > ul
    li
    > span:nth-child(3)
    img,
  .howworkStyle
    > div
    > section
    > div:nth-child(10)
    > ul
    li
    > span:nth-child(3)
    img {
    max-width: 24px;
  }
  .howworkStyle > div > section > div:nth-child(9) > ul li > p,
  .howworkStyle > div > section > div:nth-child(10) > ul li > p {
    display: block;
    font-size: 11px;
    margin-top: 8px;
  }
  .howworkStyle
    > div
    > section
    > div:nth-child(9)
    > ul
    li
    > span:nth-child(2)
    img,
  .howworkStyle
    > div
    > section
    > div:nth-child(10)
    > ul
    li
    > span:nth-child(2)
    img,
  .howworkStyle
    > div
    > section
    > div:nth-child(9)
    > ul
    li
    > span:nth-child(3)
    img,
  .howworkStyle
    > div
    > section
    > div:nth-child(10)
    > ul
    li
    > span:nth-child(3)
    img {
    margin-right: 8px;
  }
  .howworkStyle > div > section > div:nth-child(9) > ul li,
  .howworkStyle > div > section > div:nth-child(10) > ul li {
    width: 100%;
  }
  .howworkStyle > div > section > div:nth-child(9) > ul li:first-child,
  .howworkStyle > div > section > div:nth-child(10) > ul li:first-child {
    margin-right: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #cad1d9;
    margin-bottom: 20px;
  }
}
@media (max-width: 481px) {
  .howworkStyle > div > section > div:nth-child(3) > img {
    width: 100%;
    max-width: 210px;
  }
}
/* progress */
.progressBar {
  position: relative;
  overflow: hidden;
}
.progressBar > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1px;
}
